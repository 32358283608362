<template>
  <div class="equipment-statistics" ref="equipmentStatistics">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm
        :showMultDay="false"
        :alarmTypeList="alarmTypeList"
        :alarmTypeShow="true"
        :chooseToday="true"
        @getFormVal="onSearch"
      >
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </analysisForm>
    </div>
    <el-table
      id="outputTable"
      :data="tableData"
      stripe
      :height="tableHeight"
      width="100%"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="licensePlateColor"
        label="车牌颜色"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ colorObj[Number(scope.row.licensePlateColor)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.type)]
        }}</template>
      </el-table-column>
      <el-table-column prop="deviceNo" label="终端号"></el-table-column>
      <el-table-column
        prop="alarmType"
        label="故障类型"
        show-overflow-tooltip
        width="200"
      >
        <template slot-scope="scope">
          {{ alarmTypeObj[Number(scope.row.alarmType)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="latestAlarmTime"
        label="最后报警时间"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="alarmCount" label="报警次数"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span class="sign pointer" @click="onShowDetial(scope.row)"
            >查看详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 故障详情弹窗 -->
    <el-dialog
      title="故障报警详情"
      :visible.sync="dialogAlarmVisible"
      width="60%"
      @close="cancelDia"
    >
      <div class="body">
        <el-table
          :data="detailData"
          width="100%"
          height="80%"
          v-loading="loadingTable"
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="车牌号" show-overflow-tooltip>
            <template>{{ dCph }}</template>
          </el-table-column>
          <el-table-column prop="deviceNo" label="终端号">
            <template>{{ dDeviceNo }}</template>
          </el-table-column>
          <el-table-column
            prop="alarmType"
            label="故障类型"
            show-overflow-tooltip
            width="180"
          >
            <template slot-scope="scope">
              {{ alarmTypeObj[Number(scope.row.alarmType)] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmNo"
            label="故障编号"
            show-overflow-tooltip
            width="290"
          ></el-table-column>
          <el-table-column
            prop="alarmSignTime"
            label="故障时间"
            width="160"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogAlarmVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="dialogAlarmVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import { queryDeviceFaultForm, queryFaultDetail } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

export default {
  name: "equipmentStatistics",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        deviceTypes: [Number(sessionStorage.getItem('system'))]
        // system:sessionStorage.getItem('system')
      },
      typeObj: {},
      alarmTypeObj: {},
      colorObj: {},
      total: 0,
      exportForm: {},
      downLoadStatus: false,
      dialogAlarmVisible: false,
      detailData: [],
      dCph: '',
      dDeviceNo: '',
      loadingTable: true,
      alarmTypeList: [
        {
          dictValue: '储存单元',
          dictCode: '3003',
        },
        {
          dictValue: 'GNSS 天线未接或被剪断',
          dictCode: '3031',
        },
        {
          dictValue: 'GNSS 天线短路',
          dictCode: '3032',
        },
      ],
    };
  },
  methods: {
    cancelDia () {
      this.dCph = '';
      this.dDeviceNo = '';
      this.detailData = [];
    },
    onShowDetial (row) {
      this.dialogAlarmVisible = true;
      const data = {
        terminalPhoneNo: row.deviceNo,
        alarmEndTime: row.latestAlarmTime,
        alarmType: row.alarmType
      }
      this.dCph = row.cph;
      this.dDeviceNo = row.deviceNo;
      this.loadingTable = true;
      queryFaultDetail(data).then(res => {
        this.detailData = res;
        this.loadingTable = false;
        // this.$nextTick(() => {
        //   this.$refs.tableName.doLayout();
        // })
      }).catch(err => {
        console.log(err)
      })
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.equipmentStatistics.clientHeight;
      let buttonsHeight = this.$refs.functionButtons.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10;
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `设备故障统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/vehicleStatistics/deviceFaultExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击查询
    onSearch (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.alarmType = value.alarmType;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    //获取数据
    getListByField () {
      let data = { ...this.form };
      queryDeviceFaultForm(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1;
            this.getListByField();
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getListByField();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,D0010" }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY);
          this.colorObj = formatDict(res.data.CPYS);
          this.alarmTypeObj = formatDict(res.data.D0010);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.equipment-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
    .sign {
      color: themed('b4');
    }
    .pointer {
      cursor: pointer;
    }
  }
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}
.equipment-statistics {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>
