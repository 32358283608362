var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "equipmentStatistics", staticClass: "equipment-statistics" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "functionButtons",
          staticClass: "function-buttons"
        },
        [_c("el-button", { attrs: { type: "primary", size: "small" } })],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "analysisForm",
            {
              attrs: {
                showMultDay: false,
                alarmTypeList: _vm.alarmTypeList,
                alarmTypeShow: true,
                chooseToday: true
              },
              on: { getFormVal: _vm.onSearch }
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.tableData.length === 0,
                    loading: _vm.downLoadStatus
                  },
                  on: { click: _vm.exportData }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight,
            width: "100%"
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "licensePlateColor",
              label: "车牌颜色",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.colorObj[Number(scope.row.licensePlateColor)]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "车辆类型",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(_vm.typeObj[Number(scope.row.type)]))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "deviceNo", label: "终端号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmType",
              label: "故障类型",
              "show-overflow-tooltip": "",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.alarmTypeObj[Number(scope.row.alarmType)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "latestAlarmTime",
              label: "最后报警时间",
              width: "180",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmCount", label: "报警次数" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "sign pointer",
                        on: {
                          click: function($event) {
                            return _vm.onShowDetial(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "故障报警详情",
            visible: _vm.dialogAlarmVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAlarmVisible = $event
            },
            close: _vm.cancelDia
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingTable,
                      expression: "loadingTable"
                    }
                  ],
                  attrs: { data: _vm.detailData, width: "100%", height: "80%" }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号" }
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "车牌号", "show-overflow-tooltip": "" } },
                    [[_vm._v(_vm._s(_vm.dCph))]],
                    2
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { prop: "deviceNo", label: "终端号" } },
                    [[_vm._v(_vm._s(_vm.dDeviceNo))]],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "alarmType",
                      label: "故障类型",
                      "show-overflow-tooltip": "",
                      width: "180"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.alarmTypeObj[Number(scope.row.alarmType)]
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "alarmNo",
                      label: "故障编号",
                      "show-overflow-tooltip": "",
                      width: "290"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "alarmSignTime",
                      label: "故障时间",
                      width: "160",
                      "show-overflow-tooltip": ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function($event) {
                      _vm.dialogAlarmVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogAlarmVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }